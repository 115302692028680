<template>
  <v-dialog
    v-model="toggle"
    scrollable
    transition="slide-y-reverse-transition"
    style="max-height: 90vh !important;"
    light
    fullscreen
  >
    <v-card class="privacy-content white">
      <v-btn
        absolute
        top
        right
        fab
        icon
        class="mt-8 mr-n4"
        @click="close"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
      <v-card-text 
        class="py-4 grey--text text--darken-3"
      >
        <icon-base
          width="37"
          height="43"
          name="hive"
          class="hive mt-4 mb-8 black--text"
          style="height: 32px;"
        >
          <brand-symbol />
        </icon-base>

        <h2 class="title text-h6 mt-4 grey--text text--darken-4">
          Política de Privacidade
        </h2>
        <h3 class="overline my-2 mb-4">
          App Mobees para Motoristas
        </h3>

        <p>
          Este aplicativo é mantido e operado por MOBEES ON WHEELS BRASIL LTDA.
        </p>
        <p>
          Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso aplicativo. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).
        </p>
        <p>
          Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política de privacidade, que contém informações importantes sobre:
        </p>
        <ul class="mb-8">
          <li>Quem deve utilizar nosso aplicativo</li>
          <li>Quais dados coletamos e o que fazemos com eles;	 </li>
          <li>Seus direitos em relação aos seus dados pessoais; e	 </li>
          <li>Como entrar em contato conosco.</li>
        </ul>

        <h4 class="subtitle-2">
          1. Quem deve utilizar nosso aplicativo
        </h4>
        <p>
          Nosso aplicativo só deve ser utilizado por pessoas com mais de dezoito anos de idade. Sendo assim, crianças e adolescentes não devem utilizá-lo.
        </p>
        
        <h4 class="subtitle-2">
          2. Dados que coletamos e motivos da coleta
        </h4>
        <p>
          Nosso aplicativo coleta e utiliza alguns dados pessoais de nossos usuários, de acordo com o disposto nesta seção.
        </p>
        <h4 class="subtitle-2 font-italic">
          2.1. Dados pessoais fornecidos expressamente pelo usuário
        </h4>
        <p>
          Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem expressamente ao utilizar nosso aplicativo.
        </p>
        <p class="indent">
          Dados do motorista (avaliação de perfil):
        </p>
        <ul class="mb-4">
          <li>Nome completo;</li>
          <li>Número do CPF;	 </li>
          <li>Endereço de e-mail;	 </li>
          <li>Número do telefone celular;</li>	 
          <li>CEP;	 </li>
          <li>País;	 </li>
          <li>UF;	 </li>
          <li>Cidade;	 </li>
          <li>Bairro;	 </li>
          <li>Logradouro;	 </li>
          <li>Número no logradouro;	 </li>
          <li>Complemento;	 </li>
          <li>Endereço de e-mail da pessoa que indicou o usuário;	 </li>
          <li>Gênero;	 </li>
          <li>Foto;	 </li>
          <li>Data de nascimento;	 </li>
          <li>Local onde estaciona o veículo;	 </li>
          <li>Tempo como motorista de aplicativo;	 </li>
          <li>Modalidade de trabalho;	 </li>
          <li>Nível de envolvimento com a atividade de transporte;	 </li>
          <li>Quantidade de horas rodadas diariamente;	 </li>
          <li>Região mais rodada;	 </li>
          <li>Nota no aplicativo;	 </li>
          <li>Habilitação de coleta de dados pelo GPS;	 </li>
          <li>Habilitação de notificações pelo aplicativo;	 </li>
          <li>Nome da locadora do veículo;	 </li>
          <li>Marca do veículo;	 </li>
          <li>Modelo do veículo;	 </li>
          <li>Ano de fabricação do veículo;	 </li>
          <li>Situação do veículo (emprestado, alugado ou próprio);	 </li>
          <li>Grau de parentesco/relacionamento com a pessoa que emprestou o veículo;</li>
          <li>Mensagens de contato;	  </li>
        </ul>
        <p class="indent">
          Dados do motorista (documentação):
        </p>
        <ul>
          <li>Número do RG;	 </li>
          <li>Foto do RG;	 </li>
          <li>Órgão de expedição do RG;	</li>
          <li>Comprovante de residência; </li>
          <li>Número da CNH;	 </li>
          <li>Foto da CNH;	 </li>
          <li>Foto do documento do veículo;	 </li>
          <li>Placa do veículo;	 </li>
          <li>Chassi do veículo;	 </li>
          <li>Cor do veículo;	 </li>
          <li>Número do renavam do veículo;	 </li>
          <li>Nome da pessoa que emprestou o veículo;	 </li>
          <li>Endereço de e-mail da pessoa que emprestou o veículo;	 </li>
          <li>Número do RG da pessoa que emprestou o veículo;	 </li>
          <li>Foto do RG da pessoa que emprestou o veículo;	 </li>
          <li>Órgão de expedição do RG da pessoa que emprestou o veículo;	 </li>
          <li>Número do CPF da pessoa que emprestou o veículo;	 </li>
          <li>Foto do CPF da pessoas que emprestou o veículo;	</li>
        </ul>
        <p class="indent">
          Dados do motorista (assinatura):
        </p>
        <ul>
          <li>Termo de autorização de utilização do veículo;	 </li>
        </ul>
        <p class="indent">
          Dados do parceiro:
        </p>
        <ul>
          <li>Banco;	 </li>
          <li>Número da agência bancária;	 </li>
          <li>Número da conta bancária com dígito verificador;	 </li>
          <li>Tipo da conta bancária;	 </li>
          <li>Complemento da conta bancária;	</li>
        </ul>
        <p class="">
          A coleta destes dados ocorre nos seguintes momentos:
        </p>
        <ul>
          <li>quando o usuário complementa seus dados para avaliação de perfil ;	 </li>
          <li>quando o usuário complementa seus dados na fase de documentação; 	 </li>
          <li>quando o usuário assina o termo de autorização de utilização do veículo;</li>	 
          <li>quando o usuário é selecionado e agendado para instalação; 	 </li>
          <li>quando o usuário envia um e-mail ou uma mensagem por Whatsapp;	</li>
        </ul>
        <p class="">
          Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:
        </p>
        <ul>
          <li>para que o usuário (motorista) participe do processo de seleção de novos parceiros;	 </li>
          <li>para que o usuário (parceiro) seja atendido conforme Termos e Condições;	</li>
        </ul>
        <h4 class="subtitle-2 font-italic">
          2.2. Dados pessoais obtidos de outras formas
        </h4>
        <p>
          Nós coletamos os seguintes dados pessoais de nossos usuários:
        </p>
        <ul>
          <li>dados de geolocalização;</li>
        </ul>
        <p>
          A coleta destes dados ocorre nos seguintes momentos:
        </p>
        <ul>
          <li>quando o usuário habilita a opção GPS, o sistema coleta dados de geolocalização a cada 5 minutos. A Mobees reserva o direito de aumentar ou diminuir essa frequência em função de avaliação de desempenho do aplicativo;</li>
        </ul>
        <p>
          Estes dados são coletados com as seguintes finalidades:
        </p>
        <ul>
          <li>aperfeiçoar a avaliação do perfil do motorista;</li>
        </ul>
        <h4 class="subtitle-2 font-italic">
          2.3. Dados sensíveis
        </h4>
        <p>
          <b>Não</b> serão coletados dados sensíveis de nossos usuários, assim entendidos aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, <b>não</b> haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
        </p>
        <h4 class="subtitle-2 font-italic">
          2.4. Coleta de dados não previstos expressamente
        </h4>
        <p>
          Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.
        </p>
        <p>
          Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do aplicativo.
        </p>

        <h4 class="subtitle-2">
          3. Compartilhamento de dados pessoais com terceiros
        </h4>
        <p>
          Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é possível que o façamos para cumprir alguma determinação legal ou regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade pública.
        </p>

        <h4 class="subtitle-2">
          4. Por quanto tempo seus dados pessoais serão armazenados
        </h4>
        <p>
          Os dados pessoais coletados pelo aplicativo são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do aplicativo e as disposições legais ou regulatórias aplicáveis.
        </p>
        <p>
          Uma vez expirados os períodos de armazenamento dos dados pessoais, eles são removidos de nossas bases de dados ou anonimizados, salvo nos casos em que houver a possibilidade ou a necessidade de armazenamento em virtude de disposição legal ou regulatória.
        </p>

        <h4 class="subtitle-2">
          5. Bases legais para o tratamento de dados pessoais
        </h4>
        <p>
          Uma base legal para o tratamento de dados pessoais nada mais é que um fundamento jurídico, previsto em lei, que justifica o justifica. Assim, cada operação de tratamento de dados pessoais precisa ter uma base legal a ela correspondente.
        </p>
        <p>
          Nós tratamos os dados pessoais de nossos usuários nas seguintes hipóteses:
        </p>
        <ul>
          <li>mediante o consentimento do titular dos dados pessoais;</li>
          <li>quando necessário para atender aos interesses legítimos do controlador ou de terceiro.</li>
        </ul>
        <h4 class="subtitle-2 font-italic">
          5.1. Consentimento
        </h4>
        <p>
          Determinadas operações de tratamento de dados pessoais realizadas em nosso aplicativo dependerão da prévia concordância do usuário, que deverá manifestá-la de forma livre, informada e inequívoca.
        </p>
        <p>
          O usuário poderá revogar seu consentimento a qualquer momento, sendo que, não havendo hipótese legal que permita ou que demande o armazenamento dos dados, os dados fornecidos mediante consentimento serão excluídos.
        </p>
        <p>
          Além disso, se desejar, o usuário poderá não concordar com alguma operação de tratamento de dados pessoais baseada no consentimento. Nestes casos, porém, é possível que não possa utilizar alguma funcionalidade do aplicativo que dependa daquela operação. As consequências da falta de consentimento para uma atividade específica são informadas previamente ao tratamento.
        </p>
        <h4 class="subtitle-2 font-italic">
          5.2. Legítimo interesse
        </h4>
        <p>
          Para determinadas operações de tratamento de dados pessoais, nos baseamos exclusivamente em nosso interesse legítimo. Para saber mais sobre em quais casos, especificamente, nos valemos desta base legal, ou para obter mais informações sobre os testes que fazemos para termos certeza de que podemos utilizá-la, entre em contato com nosso Encarregado de Proteção de Dados Pessoais por algum dos canais informados nesta Política de Privacidade, na seção "Como entrar em contato conosco".
        </p>

        <h4 class="subtitle-2">
          6. Direitos do usuário
        </h4>
        <p>
          O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais:
        </p>
        <ul>
          <li>confirmação da existência de tratamento;	 </li>
          <li>acesso aos dados;	 </li>
          <li>correção de dados incompletos, inexatos ou desatualizados;	 </li>
          <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;	 </li>
          <li>portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;	 </li>
          <li>eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;	 </li>
          <li>informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;	</li> 
          <li>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;	 </li>
          <li>revogação do consentimento.</li>
        </ul>
        <p>
          É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de dados tratados com fundamento em bases legais distintas do consentimento, a menos que os dados seja desnecessários, excessivos ou tratados em desconformidade com o previsto na lei.
        </p>
        <h4 class="subtitle-2 font-italic">
          6.1. Como o titular pode exercer seus direitos
        </h4>
        <p>
          Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, poderemos solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.
        </p>

        <h4 class="subtitle-2">
          7. Medidas de segurança no tratamento de dados pessoais
        </h4>
        <p>
          Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.
        </p>
        <p>
          As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.
        </p>
        <p>
          Entre as medidas de segurança adotadas por nós, destacamos as seguintes:
        </p>
        <ul>
          <li>Os dados de nossos usuários são armazenados em ambiente seguro;	 </li>
          <li>Limitamos o acesso aos dados de nossos usuários, de modo que terceiros não autorizados não possam acessá-los;	 </li>
          <li>Utilizamos certificado SSL (Secure Socket Layer), de modo que a transmissão de dados entre os dispositivos dos usuários e nossos servidores aconteça de forma criptografada;	 </li>
          <li>Mantemos registros de todos aqueles que têm, de alguma forma, contato com nossos dados.</li>
        </ul>
        <p>
          Ainda que adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle.
        </p>
        <p>
          De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados.
        </p>

        <h4 class="subtitle-2">
          8. Reclamação a uma autoridade de controle
        </h4>
        <p>
          Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, os titulares de dados pessoais que se sentirem, de qualquer forma, lesados, podem apresentar reclamação à Autoridade Nacional de Proteção de Dados.
        </p>

        <h4 class="subtitle-2">
          9. Alterações nesta política
        </h4>
        <p>
          A presente versão desta Política de Privacidade foi atualizada pela última vez em: 29/09/2020.
        </p>
        <p>
          Nos reservamos o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas em nosso aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
        </p>
        <p>
          Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.
        </p>
        <h4 class="subtitle-2">
          10. Como entrar em contato conosco
        </h4>
        <p>
          Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, entre em contato com nosso Encarregado de Proteção de Dados Pessoais, por algum dos canais mencionados abaixo:
        </p>
        <p class="indent">
          <em>E-mail:</em> dpo@mobees.com.br
        </p>
        <p class="indent">
          <em>Endereço postal:</em> Ladeira da Glória, 26, Bloco III, 2 pavimento, sala 225, Glória, Rio de Janeiro, RJ, 22.211-120
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="grey"
          text
          @click="close"
        >
          Voltar
        </v-btn>
        <!-- <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click="acceptTOS(true)"
        >
          Aceitar
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90% !important;
  }

  .privacy-content {
    border-radius: 0 !important;
  }

  .privacy-content .subtitle-2 { 
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .privacy-content .indent { 
    padding-left: 8px;
  }
  .privacy-content ul { 
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .privacy-content ol { 
    padding: 1rem 0 0;
    list-style-position: outside;
    counter-reset: item;
  }
  .privacy-content ol li { 
    display: inline-block;
    padding-bottom: 1rem;
  }
  .privacy-content ol>li:before {
    font-weight: 700;
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
  .privacy-content ol ul {
    padding: 1rem 0 0 1rem;
    list-style-position: outside;
    list-style-type: square !important;
  }
  .privacy-content ol ul>li:before {
    content: '■ ';
  }

</style>

<script>
  // Utilities
  import {
    mdiClose
  } from '@mdi/js'
  import { sync } from 'vuex-pathify'
  import services from '@/services.js'

  export default {
    name: 'PrivacyPolicy',

    data: () => ({
      icons: {
        mdiClose
      }
    }),

    computed: {
      toggle: sync('app/views@privacy.toggle'),
      // user: sync('user'),
      // toast: sync('app/toast'),
    },

    methods: {
      ...services,

      close () {
        this.toggle = false;
        if (this.$route.name=='Política de Privacidade') this.$router.push('/');
      },

    },

    mounted () {
    },

  }
</script>
